import { WindowLocation } from "@reach/router";
import {
  Button,
  LittersChoiceCards,
  LittersSubmit,
  PageTitle,
  Wrapper,
} from "@royalcanin-fr-loyalty/ui-kit";
import React, { useState } from "react";
import { Col, Row } from "react-styled-flexboxgrid";
import { Layout } from "../../components/Layout";
import { linkTo, useLocationContext } from "../../lib/LocationProvider";
import { withPage } from "../../lib/withPage";
import Error404 from "../404";

const LittersChoice = ({ location }: { location: WindowLocation<any> }) => {
  const { litter } = useLocationContext().query;
  const [state, setState] = useState<{ [key: string]: string }>({});
  let nextLink = "";

  if (!litter) {
    return <Error404 location={location} />;
  }

  if (state.kit && !state.ad) {
    nextLink = linkTo(`/litters/kits`, {
      litter,
    });
  } else if (!state.kit && state.ad) {
    nextLink = linkTo(`/litters/ad`, {
      litter,
    });
  } else if (state.kit && state.ad) {
    nextLink = linkTo(`/litters/kits`, {
      litter,
      next: "ad",
    });
  } else {
    nextLink = linkTo(`/litters/success`, {
      litter,
    });
  }

  return (
    <Layout>
      <Wrapper>
        <PageTitle subTitle="Que désirez-vous faire ?">
          Déclarer une portée
        </PageTitle>
        <Row center="sm">
          <Col xs={12} sm={10} md={10} lg={8}>
            <LittersChoiceCards
              onChange={(e: any) =>
                setState({
                  ...state,
                  [e.currentTarget.name]: !state[e.currentTarget.name],
                })
              }
            />
            <LittersSubmit backLink="/litters">
              <Button href={nextLink}>Continuer</Button>
            </LittersSubmit>
          </Col>
        </Row>
      </Wrapper>
    </Layout>
  );
};

export default withPage(LittersChoice, {
  title: "Portées",
});
